import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom'
import { CheckContextProvider } from './context/Checka';
import { useCookies } from 'react-cookie';
import StroCarga from './pages/StroCarga';
import StroChoque from './pages/StroChoque';
import Login from './pages/Login';
import TablaCarga from './pages/CargaTabla';
import StroContractuales from './pages/StroContractuales';
import Ampliaciones from './pages/Ampliaciones';
function App() {
  const [cookies] = useCookies(['user']);
  return (
    <HashRouter>
      <CheckContextProvider>
        <Routes>
          <Route exact path='/' element={cookies.user ? <TablaCarga /> : <Login/>} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/:id' element={cookies?.user?.depto === 'cargas' ? <StroCarga /> : (cookies?.user?.depto === 'choques' ? <StroChoque /> : (cookies?.user?.depto === 'contractuales' ? <StroContractuales /> : null))} />
          <Route exact path='/ampliaciones' element={cookies.user ? <Ampliaciones/> : <Login/>} />
        </Routes>
      </CheckContextProvider>
    </HashRouter>
  );
}

export default App;