import './cargaTabla.css'
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import AmpliacionesTabla from '../components/ampliaciones/AmpliacionesTabla';
import Calendar from "react-calendar";
export default function Ampliaciones() {
    const [cookies, setCookie] = useCookies(["user"]);
    const [ampliaciones, setAmpliaciones] = useState([]);
    let updatedList = ampliaciones;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const ampliacionesRes = await axios.get("https://apifotos.agsseguros.online/api/ampliaciones/allAmpliaciones");
                console.log(ampliacionesRes.data);

                setAmpliaciones(ampliacionesRes.data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, []);
    const [info, setInfo] = useState({
        buscarGeneral2: "",
        poliza: "",
        nroStro: "",
        dominio: "",
        tipo: "",
        denuncia: null,
        visto : ''
    });
    const conFiltro = () => {
        if (info.poliza) {
            updatedList = updatedList.filter((d) =>
                JSON.stringify(Number(d.poliza)).includes(Number(info.poliza))
            );
        }
        if (info.nroStro) {
            updatedList = updatedList.filter((d) =>
                JSON.stringify(Number(d.stro)).includes(Number(info.nroStro))
            );
        }
        if (info.dominio) {
            updatedList = updatedList.filter((d) =>
                JSON.stringify(d.dominio)
                    .replace(/[&\\#,+()$~%.'":*?<>{}]/g, "")
                    .toUpperCase()
                    .includes(info.dominio.toUpperCase())
            );
        }
        if (info.tipo) {
            updatedList = updatedList.filter(d => 
                d.tipo === info.tipo
            )
        }
        if (info.denuncia) {
            updatedList = updatedList.filter((d) =>
                JSON.stringify(Number(d.denuncia)).includes(Number(info.denuncia))
            );
        }
        if (info.visto) {
            updatedList = updatedList.filter(d => 
                d.visto === (info.visto === 'true')
            );
        }
    };
    conFiltro()
    return (
        <>
            <main className="pe-3 ps-4">
                <div className="row pt-3 mb-3" >
                    <div className="col-9 d-flex">
                        <div class="btnBusquedaContainer shadow-lg">
                            <i class="bi bi-search pe-3"></i>
                            <input
                                class="btnBusqueda"
                                type="text"
                                placeholder="BUSCAR"
                                onChange={(e) =>
                                    setInfo((prevState) => ({
                                        ...prevState,
                                        buscarGeneral2: e.target.value,
                                    }))
                                }
                            ></input>
                        </div>
                    </div>
                </div>
                <section className='row pb-3'>
                    <section className="sectionTablaCargaContainer col-10">
                        <div className=" w-100">
                            <table className="table table-hover sectionTablaCarga shadow">
                                <thead className="tablaCargaHeader tablaCarga shadow-md">
                                    <tr>
                                        <th >
                                            <h6 className="tablaCargaHeaderText">TIPO</h6>
                                        </th>
                                        <th >
                                            <h6 className="tablaCargaHeaderText">DENUNCIA</h6>
                                        </th>
                                        <th>
                                            <h6 className="tablaCargaHeaderText">POLIZA</h6>
                                        </th>
                                        <th >
                                            <h6 className="tablaCargaHeaderText">STRO</h6>
                                        </th>
                                        <th >
                                            <h6 className="tablaCargaHeaderText">DOMINIO</h6>
                                        </th>
                                        <th className='col-4'>
                                            <h6 className="tablaCargaHeaderText">DETALLE</h6>
                                        </th>
                                        <th >
                                            <h6 className="tablaCargaHeaderText">FECHA</h6>
                                        </th>
                                        <th >
                                            <h6 className="tablaCargaHeaderText"></h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="tablaCargaBody my-scrollbar">
                                    {updatedList
                                        .map((a) => (
                                            <AmpliacionesTabla
                                                ampliacion={a}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <section className="sectionFiltroTablaCarga col-2 mt-2">
                        <div className="filtroCargaCol">
                            <select
                                className="btnBusqueda ps-3"
                                onChange={(e) =>
                                    setInfo((prevState) => ({
                                        ...prevState,
                                        tipo: e.target.value,
                                    }))
                                }
                            >
                                <option value="">TIPO</option>
                                <option value="AMPLIACION">AMPLIACION</option>
                                <option value="RECTIFICACION">RECTIFICACION</option>
                            </select>
                        </div>
                        <div className="filtroCargaCol mt-3">
                            <input
                                class="btnBusqueda ps-3"
                                type="text"
                                placeholder="DENUNCIA"
                                onChange={(e) =>
                                    setInfo((prevState) => ({
                                        ...prevState,
                                        denuncia: e.target.value,
                                    }))
                                }
                            ></input>
                        </div>
                        <div className="filtroCargaCol mt-3">
                            <input
                                class="btnBusqueda ps-3"
                                type="text"
                                placeholder="POLIZA"
                                onChange={(e) =>
                                    setInfo((prevState) => ({
                                        ...prevState,
                                        poliza: e.target.value,
                                    }))
                                }
                            ></input>
                        </div>
                        <div className="filtroCargaCol mt-3">
                            <input
                                class="btnBusqueda ps-3"
                                type="text"
                                placeholder="STRO"
                                onChange={(e) =>
                                    setInfo((prevState) => ({
                                        ...prevState,
                                        poliza: e.target.value,
                                    }))
                                }
                            ></input>
                        </div>
                        <div className="filtroCargaCol mt-3">
                            <input
                                class="btnBusqueda ps-3"
                                type="text"
                                placeholder="DOMINIO"
                                onChange={(e) =>
                                    setInfo((prevState) => ({
                                        ...prevState,
                                        dominio: e.target.value,
                                    }))
                                }
                            ></input>
                        </div>
                        <div className="filtroCargaCol mt-3">
                            <select
                                className="btnBusqueda ps-3"
                                onChange={(e) =>
                                    setInfo((prevState) => ({
                                        ...prevState,
                                        visto: e.target.value,
                                    }))
                                }
                            >
                                <option value="">VISTO</option>
                                <option value={true}>SI</option>
                                <option value={false}>NO</option>
                            </select>
                        </div>
                        <div className="center mt-3">
                            <Calendar
                                locale="es-419"
                                className="my-custom-calendar"
                            />
                        </div>
                        
                    </section>
                </section>
            </main>
        </>
    );
}
